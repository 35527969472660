import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  InputGroup,
  InputRightElement,
  IconButton,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import useSignup from "./useSignup";

export const ResetPasswordStage = ({ token, apiUrl }) => {
  const { t } = useTranslation();
  const { resetPassword, loading, error } = useSignup(apiUrl);
  const [showPassword, setShowPassword] = useState(false);
  const [hasReset, setHasReset] = useState(false);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    async (data) => {
      try {
        const response = await resetPassword(token, data.password);

        if (response) {
          console.log("Password reset successfully");
          setHasReset(true);
        }
      } catch (error) {
        console.error("Password reset failed:", error);
      }
    },
    [resetPassword, setHasReset, token]
  );

  if (hasReset) {
    return <Text>{t("label.PasswordResetSuccess")}</Text>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4} align="stretch">
        <FormControl isRequired isInvalid={errors.password}>
          <FormLabel>{t("label.NewPassword")}</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: true,
                minLength: 8,
                pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{7,}$/,
              })}
            />
            <InputRightElement>
              <IconButton
                aria-label="Toggle password visibility"
                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() => setShowPassword(!showPassword)}
                variant="ghost"
                tabIndex={-1}
              />
            </InputRightElement>
          </InputGroup>
          {errors.password && (
            <Text color="red.500">{t("label.invalidPassword")}</Text>
          )}
        </FormControl>
        <FormControl isRequired isInvalid={errors.confirmPassword}>
          <FormLabel>{t("label.ConfirmPassword")}</FormLabel>
          <Input
            type="password"
            {...register("confirmPassword", {
              required: true,
              validate: (value) => value === getValues("password"),
            })}
          />
          {errors.confirmPassword && (
            <Text color="red.500">{t("label.passwordMismatch")}</Text>
          )}
        </FormControl>
      </VStack>

      <ModalFooter>
        <Button type="submit" colorScheme="green" mb={2} isLoading={loading}>
          {loading ? <Spinner size="sm" mr={2} /> : null}
          {t("button.ResetPassword")}
        </Button>
      </ModalFooter>

      {error && (
        <Text color="red.500" mt={2}>
          {t("label.PasswordResetFailed")}
        </Text>
      )}
    </form>
  );
};
